import type { TypedSupabaseClient } from '@/lib/supabase.types'
import type { DatabaseRow } from '@/types/database.table.types'

export type CrewMemberWithDivision = DatabaseRow<'crew_members'> & {
	division: DatabaseRow<'divisions'> | null
	emergency_contacts: DatabaseRow<'emergency_contacts'>[]
	clothing_preferences: DatabaseRow<'clothing_info'> | null
	addresses: DatabaseRow<'addresses'>[]
}

export const fetchCrewMembers = (client: TypedSupabaseClient) => {
	const query = client
		.from('crew_members')
		.select('*, division:divisions!crew_members_division_id_fkey(*)')
		.throwOnError()
		.order('first_name')
		.returns<CrewMemberWithDivision[]>()

	return query
}

export const fetchCrewMemberById = (client: TypedSupabaseClient, id: string) => {
	return client.from('crew_members').select('*').eq('id', id).throwOnError().single()
}

export const fetchCrewMemberByUserId = (client: TypedSupabaseClient, userId: string) => {
	return client
		.from('crew_members')
		.select('*')
		.eq('profile_id', userId)
		.returns<DatabaseRow<'crew_members'>>()
		.throwOnError()
		.single()
}

export const fetchCrewMemberByUserId2 = async (
	client: TypedSupabaseClient,
	userId: string,
): Promise<DatabaseRow<'crew_members'> | null> => {
	const { data: member } = await client
		.from('crew_members')
		.select('*')
		.eq('profile_id', userId)
		.returns<DatabaseRow<'crew_members'>>()
		.throwOnError()
		.single()

	return member
}

export const fetchDispatchableCrewMembersCount = (
	client: TypedSupabaseClient,
	divisions?: string[],
) => {
	const query = client
		.from('crew_members')
		.select('*', { count: 'exact', head: true })
		.eq('is_dispatchable', true)
		.throwOnError()

	if (divisions?.length) {
		query.in('division_id', divisions)
	}

	return query
}

export const fetchDispatchableCrewMembers = (client: TypedSupabaseClient) => {
	return client
		.from('crew_members')
		.select('*, division:division_id(*)')
		.eq('is_dispatchable', true)
		.neq('employment_status', 'Not employed')
		.order('first_name', { ascending: true })
		.throwOnError()
		.returns<CrewMemberWithDivision[]>()
}

export const fetchMaintenanceCrewMembers = (client: TypedSupabaseClient) => {
	return client
		.from('crew_members')
		.select('*')
		.eq('is_maintenance', true)
		.neq('employment_status', 'Not employed')
		.order('first_name', { ascending: true })
		.throwOnError()
		.returns<DatabaseRow<'crew_members'>[]>()
}

export type CrewLeadersWithDivision = DatabaseRow<'crew_members'> & {
	division: DatabaseRow<'divisions'>
}

export const fetchCrewLeaders = (client: TypedSupabaseClient) => {
	const query = client
		.from('crew_members')
		.select('*, division:division_id(*)')
		.eq('is_crew_leader', true)
		.neq('is_dispatchable', false)
		.neq('employment_status', 'Not employed')
		.order('first_name', { ascending: true })
		.returns<CrewLeadersWithDivision[]>()

	return query
}
