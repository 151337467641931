import { fetchCrewMemberByUserId2 } from '@/queries/crewMemberQueries'
import { fetchTeamById } from '@/queries/teamQueries'
import { supabaseBrowserInstance } from './supabaseBrowser'

const getAuth = async () => {
	const {
		data: { user },
	} = await supabaseBrowserInstance.auth.getUser()
	if (!user) {
		return {
			user: null,
			teamAddress: null,
			teamId: '',
			team: null,
			session: null,
			actingAsCrewMember: null,
			betaAccess: false,
		}
	}
	const actingAsCrewMember = await fetchCrewMemberByUserId2(supabaseBrowserInstance, user.id)

	if (!actingAsCrewMember) {
		return {
			user,
			teamAddress: null,
			teamId: '',
			team: null,
			actingAsCrewMember: null,
			betaAccess: false,
		}
	}
	const { data: team } = await fetchTeamById(supabaseBrowserInstance, actingAsCrewMember?.team_id)

	if (!team) {
		return {
			user,
			teamAddress: null,
			teamId: '',
			team: null,
			actingAsCrewMember: null,
			betaAccess: false,
		}
	}
	const { data: teamAddress } = await supabaseBrowserInstance
		.from('addresses')
		.select('*')
		.eq('team_id', team?.id)
		.eq('address_type', 'company')
		.maybeSingle()
	return {
		user,
		teamAddress,
		teamId: actingAsCrewMember?.team_id || '',
		team: team || null,
		actingAsCrewMember,
		betaAccess: team?.beta_access || false,
	}
}

export const auth = getAuth()
