import { logger } from '@/utils/logger'
// lib/supabase.ts
import type { Database } from '@/types/database.types'
import { createBrowserClient } from '@supabase/ssr'
import type { TypedSupabaseClient } from '@/lib/supabase.types'

const supabaseUrl = process.env.NEXT_PUBLIC_SUPABASE_URL || ''
const supabaseKey = process.env.NEXT_PUBLIC_SUPABASE_ANON_KEY || ''

let supabase: TypedSupabaseClient | null = null

export const getSupabaseBrowser = (): TypedSupabaseClient => {
	try {
		if (!supabaseUrl || !supabaseKey) {
			throw new Error('Supabase URL and Key are required.')
		}
		if (!supabase) {
			supabase = createBrowserClient<Database>(supabaseUrl, supabaseKey, {
				auth: { persistSession: true },
			})
		}
		return supabase
	} catch (error) {
		logger.error('Error creating Supabase client:', error)
		throw new Error('Error creating Supabase client')
	}
}

const supabaseBrowserInstance = getSupabaseBrowser()

export { supabaseBrowserInstance }
